<template>
    <transition name="slide-up">
        <div v-show="show" class="modal-action-sheet">
            <div class="content">
                <div class="notice">
                    <p>{{ $translate(`REFUND_NOT_SUBTRACT_${reasonNum}`) }}</p>
                    <p>소개팅권 환급 횟수가 차감되지 않아요!</p>
                </div>
                <p class="c-pink-deep f-medium" v-html="$translate(`REFUND_NOT_SUBTRACT_WARNING_${reasonNum}`)" />
            </div>
            <div class="button">
                <button @click="close" v-html="$translate('CONFIRM')" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalNotSubstract',
    data: () => ({
        show: false,
    }),
    props: {
        options: Object,
    },
    computed: {
        reasonNum() {
            return this.options.reason.id
        },
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        })
    },
    methods: {
        close() {
            this.show = false
            setTimeout(() => {
                this.$emit('close')
                this.$router.push({ name: 'Refund5ExcludePage' })
            }, 400)
        },
    },
}
</script>

<style scoped lang="scss">
.modal-action-sheet {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px !important;
    width: 100vw;
    height: 214px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notice {
        color: black;
        font-size: 18px;
        margin-bottom: 12px;
    }
    button {
        width: 100%;
        height: 48px;
        border: none;
        background: $purple-primary;
        border-radius: 8px;
        font-weight: normal;
        color: white;
        @include f-regular;
    }
}
</style>
